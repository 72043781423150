import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// ---------------------------------------------
import Header from "../components/header";
import Layout from "../components/layout";
import Transition from "../components/transition";
import SEO from "../components/seo";

const LegalPage = ({ data, location }) => {

  const {
    title,
    longText
  } = data.craft.entries[0];

  return (
    <Layout>
      <SEO />
      <Transition
        location={location}
        render={handleLink => (
          <>
            <Header handleLink={handleLink} mart />
            <main className="head-offset">

              {/* Meta info below logo */}
              <div className="project-filter in-journal">
                <span className="heading--4">{title}</span>
              </div>

              {/* Hero Body */}
              {longText && (
                <article
                  className="article-body"
                  dangerouslySetInnerHTML={{ __html: longText }}
                />
              )}
            </main>
          </>
        )}
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPageQuery($slug: [String!]){
    craft {
      entries(type: "Legal", slug: $slug) {
        ... on Craft_legal_legal_Entry {
          title
          longText
        }
      }
    }
  }
`
